exports.components = {
  "component---src-pages-autor-js": () => import("./../../../src/pages/autor.js" /* webpackChunkName: "component---src-pages-autor-js" */),
  "component---src-pages-codigos-e-sinais-de-transito-js": () => import("./../../../src/pages/codigos-e-sinais-de-transito.js" /* webpackChunkName: "component---src-pages-codigos-e-sinais-de-transito-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-detalhes-do-blog-js": () => import("./../../../src/pages/detalhes-do-blog.js" /* webpackChunkName: "component---src-pages-detalhes-do-blog-js" */),
  "component---src-pages-detalhes-do-servico-js": () => import("./../../../src/pages/detalhes-do-servico.js" /* webpackChunkName: "component---src-pages-detalhes-do-servico-js" */),
  "component---src-pages-equipe-js": () => import("./../../../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-repositorio-upm-js": () => import("./../../../src/pages/repositorio-upm.js" /* webpackChunkName: "component---src-pages-repositorio-upm-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

